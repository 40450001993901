import { useState } from 'react';

import './App.css';

function App() {
  const [conflicts, setConflicts] = useState([]);

  function handleUpload(e) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const csvText = e.target.result;
        const data = parseCSV(csvText);
        findConflicts(data);
      };
      reader.readAsText(file);
    }
  }

  // parse csv to arrays
  function parseCSV(csv) {
    let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l;
    for (l of csv) {
        if ('"' === l) {
            if (s && l === p) row[i] += l;
            s = !s;
        } else if (',' === l && s) l = row[++i] = '';
        else if ('\n' === l && s) {
            if ('\r' === p) row[i] = row[i].slice(0, -1);
            row = ret[++r] = [l = '']; i = 0;
        } else row[i] += l;
        p = l;
    }

    return ret;
  }

  function findConflicts(data) {
    const courseStudents = {};

    // group students by course
    data.forEach((row, index) => {
      if (index === 0) return;

      let studentId = row[0];

      for (let i = 1; i < row.length; i++) {
        let course = row[i].trim();

        if (course) {
          if (courseStudents[course]) {
            courseStudents[course].push(studentId);
          }
          else {
            courseStudents[course] = [studentId];
          }
        }
      }
    });

    // remove course with students > 34
    for (let course in courseStudents) {
      if (courseStudents[course].length > 34) {
        delete courseStudents[course];
      }
    }

    // find conflicts
    const conflicts = [];
    const courses = Object.keys(courseStudents);

    for (let i = 0; i < courses.length; i++) {
      for (let j = i + 1; j < courses.length; j++) {
        let courseA = courses[i];
        let courseB = courses[j];

        const commonStudents = courseStudents[courseA].filter(studentId => courseStudents[courseB].includes(studentId));

        if (commonStudents.length) {
          conflicts.push({
            courseA,
            courseB,
            commonStudents,
          });
        }
      }
    }

    setConflicts(conflicts);
  }

  return (
    <div className="App">
      <h1 className="header">Course Conflict Checker</h1>
      <input type="file" onChange={handleUpload} />
      <table>
        <tr>
          <th>Course A</th>
          <th>Course B</th>
          <th>Common Students</th>
        </tr>
        {conflicts.map((conflict, i) => (
          <tr key={i}>
            <td>{conflict.courseA}</td>
            <td>{conflict.courseB}</td>
            <td>{conflict.commonStudents.join(', ')}</td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default App;
